<template>
<div>
    <v-dialog v-model="dialogRIA" max-width="1200" persistent scrollable>
        <v-card>
          <v-card-title class="headline pt-5">
            Create {{riaData.type == 1 ? 'ICS' : 'PAR'}} and References
            <v-spacer></v-spacer>
            <v-autocomplete
              v-model="formData.RefTypeID"
              :rules="[formRules.required]"
              dense
              outlined
              hide-details
              color="header"
              style="max-width: 190px"
              :items="ref_types"
              item-text="description"
              item-value="id"
              type="text"
              class="mr-5"
              :value="formData.RefTypeID"
            ></v-autocomplete>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-card-title>
          <v-card-text style="max-height: 900px">
          <v-form @submit.prevent ref="RIAFormRef">
            <v-row>
              <v-col cols="12" v-if="formData.RefTypeID == 1 || formData.RefTypeID == 3 || formData.RefTypeID == 4">
                <v-sheet color="DarkWhite" class="px-3 pt-5 mb-3" border >
                <v-autocomplete
                  v-if="formData.RefTypeID == 1"
                  v-model="formData.IARID"
                  @change="getIARItems(formData.IARID)"
                  label="IAR Code"
                  clearable
                  dense
                  outlined
                  color="header"
                  :items="iars"
                  item-text="IARCODE"
                  item-value="IARID"
                  type="text"
                ></v-autocomplete>
                <v-autocomplete
                  v-else
                  v-model="formData.IARID"
                  @change="getIARItems(formData.IARID)"
                  label="IAR Code"
                  :rules="[formRules.required]"
                  dense
                  outlined
                  color="header"
                  :items="iars"
                  item-text="IARCODE"
                  item-value="IARID"
                  type="text"
                ></v-autocomplete>
                <span v-if="formData.RefTypeID == 3">
                <v-row wrap align="center" no-gutters>
                <v-col cols="12" class="mb-4">
                  <v-icon>mdi-help-circle</v-icon> Select a RIS Code if RIS was already uploaded, otherwise choose "Not yet uploaded"
                </v-col>
              </v-row>
                <v-autocomplete
                  v-model="formData.RISID"
                  @change="getRISItems(formData.RISID)"
                  label="RIS Code"
                  :rules="[formRules.numberRequiredZeroAllowed]"
                  dense
                  outlined
                  color="header"
                  :items="rislist"
                  item-text="RISCODE"
                  item-value="RISID"
                  type="text"

                ></v-autocomplete>
                </span>
                </v-sheet>
              </v-col>
              <v-col cols="12" v-if="formData.RefTypeID == 2">
                <v-row wrap no-gutters>
                  <v-col cols="12">
                    Inspection And Acceptance Report Details
                    <v-divider class="mb-4"></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-text-field
                          dense
                          outlined
                          color="header"
                          v-model="formData4.IARCODE"
                          label="IAR Code"
                        ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-menu
                        dense
                        ref="menu16"
                        v-model="menu16"
                        :close-on-content-click="false"
                        :return-value.sync="formData4.DateCreated"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData4.DateCreated"
                            :rules="[formRules.required]"
                            label="Date Created"
                            readonly
                            outlined
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData4.DateCreated"
                          no-title
                          scrollable
                          color="header"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu16 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu16.save(formData4.DateCreated)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-autocomplete
                          v-model="formData4.UploadedSupplierID"
                          label="Supplier"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="suppliers"
                          item-text="CompanyName"
                          item-value="SupplierID"
                          type="text"
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-text-field
                          dense
                          outlined
                          color="header"
                          v-model="formData4.InvoiceNo"
                          label="Invoce No."
                        ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-menu
                        dense
                        ref="menu9"
                        v-model="menu9"
                        :close-on-content-click="false"
                        :return-value.sync="formData4.DateInvoice"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData4.DateInvoice"
                            :rules="[formRules.required]"
                            label="Invoice Date"
                            readonly
                            outlined
                            multiple
                            chips
                            small-chips
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData4.DateInvoice"
                          no-title
                          scrollable
                          color="header"
                          multiple
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu9 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu9.save(formData4.DateInvoice)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-menu
                        dense
                        ref="menu10"
                        v-model="menu10"
                        :close-on-content-click="false"
                        :return-value.sync="formData4.DateDelivered"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData4.DateDelivered"
                            :rules="[formRules.required]"
                            label="Date Delivered"
                            readonly
                            outlined
                            multiple
                            chips
                            small-chips
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData4.DateDelivered"
                          no-title
                          scrollable
                          color="header"
                          multiple
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu10 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu10.save(formData4.DateDelivered)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                  <div class="font-weight-bold font-size-18">Inspection</div>
                  <v-divider class="mb-2"></v-divider>
                  <v-row no-gutters class="mt-6">
                    <v-col cols="12">
                          <v-menu
                          dense
                          ref="menu11"
                          v-model="menu11"
                          :close-on-content-click="false"
                          :return-value.sync="formData4.DateInspectedStart"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              dense
                              v-model="formData4.DateInspectedStart"
                              :rules="[formRules.required]"
                              label="Date Inspected (Start)"
                              readonly
                              outlined
                              color="header"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-combobox>
                          </template>
                          <v-date-picker
                            v-model="formData4.DateInspectedStart"
                            no-title
                            scrollable
                            color="header"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="header" @click="menu11 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="header"
                              @click="$refs.menu11.save(formData4.DateInspectedStart)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                    </v-col>
                  <v-col cols="12">
                        <v-menu
                        dense
                        ref="menu12"
                        v-model="menu12"
                        :close-on-content-click="false"
                        :return-value.sync="formData4.DateInspectedEnd"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData4.DateInspectedEnd"
                            :rules="[formRules.required]"
                            label="Date Inspected (End)"
                            readonly
                            outlined
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData4.DateInspectedEnd"
                          no-title
                          scrollable
                          color="header"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu12 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu12.save(formData4.DateInspectedEnd)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                  </v-row>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                  <div class="font-weight-bold font-size-18">Acceptance</div>
                  <v-divider class="mb-2"></v-divider>
                  <v-row no-gutters>
                    <v-col cols="12">
                    <v-radio-group row v-model="formData4.Status" class="">
                      <v-radio
                        label="Complete Delivery"
                        color="green darken-2"
                        value="Complete"
                      ></v-radio>

                      <v-radio
                        label="Partial Delivery"
                        color="green darken-2"
                        value="Partial"
                      ></v-radio>
                    </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                          <v-menu
                          dense
                          ref="menu14"
                          v-model="menu14"
                          :close-on-content-click="false"
                          :return-value.sync="formData4.DateReceivedStart"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              dense
                              v-model="formData4.DateReceivedStart"
                              :rules="[formRules.required]"
                              label="Date Received (Start)"
                              readonly
                              outlined
                              color="header"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-combobox>
                          </template>
                          <v-date-picker
                            v-model="formData4.DateReceivedStart"
                            no-title
                            scrollable
                            color="header"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="header" @click="menu14 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="header"
                              @click="$refs.menu14.save(formData4.DateReceivedStart)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                          <v-menu
                          dense
                          ref="menu15"
                          v-model="menu15"
                          :close-on-content-click="false"
                          :return-value.sync="formData4.DateReceivedEnd"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              dense
                              v-model="formData4.DateReceivedEnd"
                              :rules="[formRules.required]"
                              label="Date Received (End)"
                              readonly
                              outlined
                              color="header"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-combobox>
                          </template>
                          <v-date-picker
                            v-model="formData4.DateReceivedEnd"
                            no-title
                            scrollable
                            color="header"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="header" @click="menu15 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="header"
                              @click="$refs.menu15.save(formData4.DateReceivedEnd)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                    </v-col>
                  </v-row>
                  </v-col>
                  <v-col cols="12" class="px-2">
                        <v-autocomplete
                          v-model="formData4.RecieverUserID"
                          label="Receiver"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="users"
                          item-text="description"
                          item-value="id"
                          type="text"
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-autocomplete
                          v-model="formData4.FundID"
                          label="Fund Code"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="funds"
                          item-text="description"
                          item-value="id"
                          type="text"
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-autocomplete
                          v-model="formData4.SpecificFundSourceID"
                          label="Specific Fund Source"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="specificfundsources"
                          item-text="description"
                          item-value="id"
                          type="text"
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    Inspection And Acceptance Report Item Details
                    <v-divider class="mb-4"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                    :headers="headers"
                    :items="formData4.items"
                    class="elevation-1"
                  >
                  <template v-slot:top>
                      <v-toolbar flat>
                        <span class="green--text text--darken-2">List Of Items</span>
                        <v-spacer></v-spacer>
                        <!-- <v-btn color="grey darken-2 white--text" class="mr-2" @click="addItem()"><v-icon class="mr-1">mdi-content-copy</v-icon> Copy Items</v-btn> -->
                        <v-btn color="green darken-2 white--text" @click="addIARItem()"><v-icon class="mr-1">mdi-plus-box</v-icon> Add Item</v-btn>
                      </v-toolbar>
                  </template>
                  <template v-slot:[`item.UnitCost`]="{ item }">
                        <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                      </template>
                      <template v-slot:[`item.Qty`]="{ item }">
                        {{ formatQty(item.Qty) || 0 }}
                      </template>
                      <template v-slot:[`item.allowableQty`]="{ item }">
                        {{ formatQty(item.allowableQty) }}
                      </template>
                      <template v-slot:[`item.editableQty`]="{ item }">
                        <v-edit-dialog :return-value.sync="item.editableQty" large>
                          <span :style="parseFloat(item.allowableQty) < parseFloat(item.editableQty) || parseFloat(item.editableQty) <= 0 ? 'color:red' : 'color:black'">{{ formatQty(item.editableQty) }}</span>
                          <template v-slot:input>
                            <v-text-field v-model="item.editableQty" type="number" :min="1" :max="parseFloat(item.allowableQty)" :rules="[parseFloat(item.allowableQty) >= parseFloat(item.editableQty), parseFloat(item.editableQty) > 0]"> </v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.ReturnType`]="{ item }">
                        <v-autocomplete
                            dense
                            outlined
                            hide-details
                            v-model="item.ReturnTypeID"
                            :rules="[formRules.required]"
                            color="header"
                            item-text="description"
                            auto-select-first
                            item-value="id"
                            :items="returnTypes">
                        </v-autocomplete>
                      </template>
                      <template v-slot:[`item.Actions`]="{ item, index }">
                      <span class="text-no-wrap">
                        <v-btn
                          color="blue darken-2"
                          class="mr-2"
                          dark
                          outlined
                          elevation="2"
                          x-small
                          @click="editItem3(item)"
                        >
                          edit
                        </v-btn>
                        <v-btn
                          v-if="isDeletableItem2(item)"
                          color="red darken-2"
                          dark
                          outlined
                          elevation="2"
                          x-small
                          @click="removeItem3(index)"
                        >
                          delete
                        </v-btn>
                        <v-btn
                          v-else
                          color="red darken-2"
                          outlined
                          elevation="2"
                          x-small
                          disabled
                        >
                          delete
                        </v-btn>
                      </span>
                    </template>
                  </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12" v-if="formData.RefTypeID == 2 || formData.RefTypeID == 3 || formData.RefTypeID == 4">
                <span v-if="formData.RISID == 0">
                  <v-row wrap align="center" no-gutters>
                    <v-col cols="12">
                      Requisition And Issuance Details
                      <v-divider class="mb-4"></v-divider>
                    </v-col>
                    <v-col cols="12" md="6" class="px-2">
                          <v-text-field
                            dense
                            outlined
                            color="header"
                            :rules="[formRules.required]"
                            v-model="formData3.RISCODE"
                            label="RIS Code"
                          ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="px-2">
                          <v-menu
                          dense
                          ref="menu5"
                          v-model="menu5"
                          :close-on-content-click="false"
                          :return-value.sync="formData3.DateCreated"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              dense
                              v-model="formData3.DateCreated"
                              :rules="[formRules.required]"
                              label="Date Created"
                              readonly
                              outlined
                              color="header"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-combobox>
                          </template>
                          <v-date-picker
                            v-model="formData3.DateCreated"
                            no-title
                            scrollable
                            color="header"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="header" @click="menu5 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="header"
                              @click="$refs.menu5.save(formData3.DateCreated)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="12" class="px-2">
                          <v-textarea
                          dense
                          outlined
                          color="header"
                          v-model="formData3.RISPurpose"
                          :rules="[formRules.required]"
                          rows="2"
                          label="RIS Purpose"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" class="px-2">
                          <v-autocomplete
                            v-model="formData3.UserID"
                            label="Requested By"
                            :rules="[formRules.required]"
                            dense
                            outlined
                            color="header"
                            :items="users"
                            item-text="description"
                            item-value="id"

                            type="text"
                          ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="px-2">
                          <v-menu
                          dense
                          ref="menu6"
                          v-model="menu6"
                          :close-on-content-click="false"
                          :return-value.sync="formData3.RequestedDate"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              dense
                              v-model="formData3.RequestedDate"
                              :rules="[formRules.required]"
                              label="Date Requested"
                              readonly
                              outlined
                              color="header"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-combobox>
                          </template>
                          <v-date-picker
                            v-model="formData3.RequestedDate"
                            no-title
                            scrollable
                            color="header"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="header" @click="menu6 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="header"
                              @click="$refs.menu6.save(formData3.RequestedDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" class="px-2">
                          <v-menu
                          dense
                          ref="menu7"
                          v-model="menu7"
                          :close-on-content-click="false"
                          :return-value.sync="formData3.ApprovedDate"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              dense
                              v-model="formData3.ApprovedDate"
                              :rules="[formRules.required]"
                              label="Date Approved"
                              readonly
                              outlined
                              color="header"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-combobox>
                          </template>
                          <v-date-picker
                            v-model="formData3.ApprovedDate"
                            no-title
                            scrollable
                            color="header"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="header" @click="menu7 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="header"
                              @click="$refs.menu7.save(formData3.ApprovedDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" class="px-2">
                          <v-menu
                          dense
                          ref="menu8"
                          v-model="menu8"
                          :close-on-content-click="false"
                          :return-value.sync="formData3.IssuedDate"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              dense
                              v-model="formData3.IssuedDate"
                              :rules="[formRules.required]"
                              label="Date Issued"
                              readonly
                              outlined
                              color="header"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-combobox>
                          </template>
                          <v-date-picker
                            v-model="formData3.IssuedDate"
                            no-title
                            scrollable
                            color="header"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="header" @click="menu8 = false">
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="header"
                              @click="$refs.menu8.save(formData3.IssuedDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                      Requisition And Issuance Item Details
                      <v-divider class="mb-4"></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                      :headers="headers"
                      :items="formData3.items"
                      class="elevation-1"
                      item-key="num"
                    >
                    <template v-slot:top>
                        <v-toolbar flat>
                          <span class="green--text text--darken-2">List Of Items</span>
                          <v-spacer></v-spacer>
                          <!-- <v-btn color="grey darken-2 white--text" class="mr-2" @click="copyItem()" v-if="formData.RefTypeID == 1"><v-icon class="mr-1">mdi-content-copy</v-icon> Copy Items From {{riaData.type == 1 ? 'ICS' : 'PAR'}}</v-btn> -->
                          <!-- <v-btn color="green darken-2 white--text" @click="addItem()"><v-icon class="mr-1">mdi-plus-box</v-icon> Add Item</v-btn> -->
                        </v-toolbar>
                    </template>
                      <template v-slot:[`item.UnitCost`]="{ item }">
                        <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                      </template>
                      <template v-slot:[`item.Qty`]="{ item }">
                        {{ formatQty(item.Qty) || 0 }}
                      </template>
                      <template v-slot:[`item.allowableQty`]="{ item }">
                        {{ formatQty(item.allowableQty) }}
                      </template>
                      <template v-slot:[`item.editableQty`]="{ item }">
                        <v-edit-dialog :return-value.sync="item.editableQty" large>
                          <span :style="parseFloat(item.allowableQty) < parseFloat(item.editableQty) || parseFloat(item.editableQty) <= 0 ? 'color:red' : 'color:black'">{{ formatQty(item.editableQty) }}</span>
                          <template v-slot:input>
                            <v-text-field v-model="item.editableQty" type="number" :min="1" :max="parseFloat(item.allowableQty)" :rules="[parseFloat(item.allowableQty) >= parseFloat(item.editableQty), parseFloat(item.editableQty) > 0]"> </v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:[`item.ReturnType`]="{ item }">
                        <v-autocomplete
                            dense
                            outlined
                            hide-details
                            v-model="item.ReturnTypeID"
                            :rules="[formRules.required]"
                            color="header"
                            item-text="description"
                            auto-select-first
                            item-value="id"
                            :items="returnTypes">
                        </v-autocomplete>
                      </template>
                      <template v-slot:[`item.Actions`]="{ item, index }">
                      <span class="text-no-wrap">
                        <v-btn
                          color="blue darken-2"
                          class="mr-2"
                          dark
                          outlined
                          elevation="2"
                          x-small
                          @click="editItem2(item)"
                        >
                          edit
                        </v-btn>
                        <v-btn
                          v-if="isDeletableItem(item)"
                          color="red darken-2"
                          dark
                          outlined
                          elevation="2"
                          x-small
                          @click="removeItem2(index)"
                        >
                          delete
                        </v-btn>
                        <v-btn
                          v-else
                          color="red darken-2"
                          outlined
                          elevation="2"
                          x-small
                          disabled
                        >
                          delete
                        </v-btn>
                      </span>
                    </template>
                    </v-data-table>
                    </v-col>
                  </v-row>
                </span>
              </v-col>
              <v-col cols="12" md="12" v-if="formData.RefTypeID == 1 || formData.RefTypeID == 2 || formData.RefTypeID == 3">
                <v-row wrap align="center" no-gutters>
                  <v-col cols="12">
                    {{riaData.type == 1 ? 'Inventory Custodian Slip' : 'Property Acknowledgement Receipt'}} Details
                    <v-divider class="mb-4"></v-divider>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-text-field
                          dense
                          outlined
                          color="header"
                          v-model="formData.CODE"
                          :rules="[formRules.required]"
                          :label="riaData.type == 1 ? 'ICS Code' : 'PAR Code'"
                        ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-menu
                        dense
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="formData.DateCreated"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData.DateCreated"
                            :rules="[formRules.required]"
                            label="Date Created"
                            readonly
                            outlined
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData.DateCreated"
                          no-title
                          scrollable
                          color="header"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu1 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu1.save(formData.DateCreated)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2" v-if="formData.RefTypeID == 1 && formData.IARID == null">
                        <v-autocomplete
                          v-model="formData.FundID"
                          label="Fund Code"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="funds"
                          item-text="description"
                          item-value="id"
                          type="text"
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2" v-if="formData.RefTypeID == 1 && formData.IARID == null">
                        <v-autocomplete
                          v-model="formData.SpecificFundSourceID"
                          label="Specific Fund Source"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="specificfundsources"
                          item-text="description"
                          item-value="id"
                          type="text"
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" class="px-2">
                        <v-textarea
                        dense
                        outlined
                        color="header"
                        v-model="formData.Purpose"
                        :rules="[formRules.required]"
                        rows="2"
                        label="Purpose"
                      ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                        <v-autocomplete
                          v-model="formData.DispenseTypeID"
                          label="Dispense Type"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="dispensetypes"
                          item-text="description"
                          item-value="id"
                          type="text"
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="px-2">
                      <v-autocomplete
                          v-if="formData.DispenseTypeID == 1"
                          v-model="formData.UserID"
                          label="Requested By"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="users"
                          item-text="description"
                          item-value="id"
                          type="text"
                        ></v-autocomplete>
                        <v-autocomplete
                          v-else-if="formData.DispenseTypeID == 2"
                          v-model="formData.UserID"
                          label="Requested By"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="users"
                          item-text="description"
                          item-value="id"
                          type="text"
                          multiple
                          chips
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="px-2">
                        <v-menu
                        dense
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="formData.DateRequested"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData.DateRequested"
                            :rules="[formRules.required]"
                            label="Date Requested"
                            readonly
                            outlined
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData.DateRequested"
                          no-title
                          scrollable
                          color="header"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu2.save(formData.DateRequested)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" md="4" class="px-2">
                        <v-menu
                        dense
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="formData.ApprovedDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData.ApprovedDate"
                            :rules="[formRules.required]"
                            label="Date Approved"
                            readonly
                            outlined
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData.ApprovedDate"
                          no-title
                          scrollable
                          color="header"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu3 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu3.save(formData.ApprovedDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" md="4" class="px-2">
                        <v-menu
                        dense
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        :return-value.sync="formData.IssuedDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData.IssuedDate"
                            :rules="[formRules.required]"
                            label="Date Issued"
                            readonly
                            outlined
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData.IssuedDate"
                          no-title
                          scrollable
                          color="header"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu4 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu4.save(formData.IssuedDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12">
                    {{riaData.type == 1 ? 'Inventory Custodian Slip' : 'Property Acknowledgement Receipt'}} Item Details
                    <v-divider class="mb-4"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                    :headers="headers"
                    :items="formData.items"
                    class="elevation-1"
                    :expanded.sync="expanded2"
                    show-expand
                    item-key="num"
                  >
                  <template v-slot:top>
                      <v-toolbar flat>
                        <span class="green--text text--darken-2">List Of Items</span>
                        <v-spacer></v-spacer>
                        <!-- <v-switch
                            v-if="items_temp.length > 0"
                            v-model="switchQty"
                            inset
                            class="mr-3"
                            color="header"
                            hide-details
                            label="Show 0 Qty"
                          ></v-switch> -->
                        <v-btn color="green darken-2 white--text" @click="addICSItem()" v-if="formData.RefTypeID == 1 && formData.IARID == null"><v-icon class="mr-1">mdi-plus-box</v-icon> Add Item</v-btn>
                      </v-toolbar>
                  </template>
                    <template v-slot:[`item.UnitCost`]="{ item }">
                      <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                    </template>
                    <template v-slot:[`item.Qty`]="{ item }">
                      <span v-if="item.Qty == 0" style="color: red;">{{ formatQty(item.Qty) }}</span>
                      <span v-else>{{ formatQty(item.Qty) }}</span>
                    </template>
                    <template v-slot:[`item.allowableQty`]="{ item }">
                      {{ formatQty(item.allowableQty) }}
                    </template>
                    <template v-slot:[`item.editableQty`]="{ item }">
                      <v-edit-dialog :return-value.sync="item.editableQty" large>
                        <span :style="parseFloat(item.allowableQty) < parseFloat(item.editableQty) || parseFloat(item.editableQty) <= 0 ? 'color:red' : 'color:black'">{{ formatQty(item.editableQty) }}</span>
                        <template v-slot:input>
                          <v-text-field v-model="item.editableQty" type="number" :min="1" :max="parseFloat(item.allowableQty)" :rules="[parseFloat(item.allowableQty) >= parseFloat(item.editableQty), parseFloat(item.editableQty) > 0]"> </v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.Actions`]="{ item, index }">
                      <span class="text-no-wrap">
                        <v-btn
                          color="blue darken-2"
                          class="mr-2"
                          dark
                          outlined
                          elevation="2"
                          x-small
                          @click="editItem(item)"
                        >
                          edit
                        </v-btn>
                        <v-btn
                          color="red darken-2"
                          dark
                          outlined
                          elevation="2"
                          x-small
                          @click="removeItem(index)"
                          v-if="formData.items.length > 1 || typeof item.ppmpItemID == 'undefined'"
                        >
                          delete
                        </v-btn>
                        <v-btn
                          color="red darken-2"
                          outlined
                          elevation="2"
                          x-small
                          v-else
                          disabled
                        >
                          delete
                        </v-btn>
                      </span>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                              <div class="mt-5">
                              <v-row no-gutters v-if="item.Qty > 5">
                                <v-col cols="12">
                                  <v-alert
                                    dense
                                    type="info"
                                    dark
                                    dismissible
                                    border="left"
                                  >
                                  You may input Brands, Models, and Serial No. in the designated fields below to fill in all fields efficiently.
                                  </v-alert>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-textarea
                                    v-model="item.MRData_str.Brand"
                                    label="Enter Brands in Comma-Separated here..."
                                    outlined
                                    dense
                                    rows="3"
                                    color="header"
                                    @keyup="fillBrands(item)"
                                    :hint="hint_brand"
                                    persistent-hint
                                  >
                                  </v-textarea>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-textarea
                                    v-model="item.MRData_str.Model"
                                    label="Enter Models in Comma-Separated here..."
                                    outlined
                                    dense
                                    rows="3"
                                    color="header"
                                    @keyup="fillModels(item)"
                                    :hint="hint_model"
                                    persistent-hint
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-textarea
                                    v-model="item.MRData_str.SerialNo"
                                    label="Enter Serial No. in Comma-Separated here..."
                                    outlined
                                    dense
                                    rows="3"
                                    color="header"
                                    @keyup="fillSerialNos(item)"
                                    :hint="hint_serial_no"
                                    persistent-hint
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                              <v-divider class="mb-6"></v-divider>
                              <v-row v-for="(mr, index) in item.MRData" :key="riaData.type + index" no-gutters>
                                <v-col cols="12" md="4" class="px-2 d-flex">
                                  <span class="pa-2 font-size-14 font-weight-bold">{{index+1}}</span>
                                  <v-text-field
                                    v-model="mr.Brand"
                                    label="Brand"
                                    outlined
                                    dense
                                    color="header"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-text-field
                                    v-model="mr.Model"
                                    label="Model"
                                    outlined
                                    dense
                                    color="header"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="px-2">
                                  <v-text-field
                                    v-model="mr.SerialNo"
                                    label="Serial Number"
                                    outlined
                                    dense
                                    color="header"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              </div>
                            </td>
                          </template>
                  </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="closeDialog()">Cancel</v-btn>
            <!-- <v-btn color="header" v-if="riaData.edit" :loading="isLoading" class="white--text" :disabled="selectedItems.length == 0" @click="updateRSMI()"><v-icon>mdi-pencil-box</v-icon> Update</v-btn> -->
            <v-btn color="header" :loading="isLoading" class="white--text" :disabled="formData.items.length == 0" @click="submitRIA()"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
          </v-card-actions>
        </v-card>
        <!-- <v-card>
          <v-card-title class="headline pt-5">Requisition and Issuance Slip</v-card-title>
          <v-card-text>
            <h2 align="center" class="ma-5">Coming Soon...</h2>
          </v-card-text>
        </v-card> -->
      </v-dialog>
      <v-dialog v-model="dialogRefType" persistent width="832px">
      <v-card>
        <v-card-title>
          <span class="subheader"> Choose documents to create:</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="dialogRefType = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
          <v-card-text>
            <v-form @submit.prevent ref="formRefType">
                  <v-chip-group
                    v-model="formData.RefTypeID"
                    active-class="header white--text"
                    class="ma-2"
                  >
                    <v-chip v-for="rt in ref_types" :value="rt.id" :key="'rt-' + rt.id" large class="px-5 chip-width">
                      <v-icon left>
                        {{rt.icon}}
                      </v-icon>
                        {{rt.description}}
                    </v-chip>
                  </v-chip-group>
                  </v-form>
          </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="dialogRefType = false"> Cancel </v-btn>
          <v-btn
            color="header"
            class="white--text"
            @click="saveRefType()"
            :loading="isLoading"
            :disabled="formData.RefTypeID == null"
          >
          <v-icon class="mr-1">mdi-checkbox-marked-outline</v-icon>
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddItem" persistent scrollable width="500px">
      <v-card>
        <v-card-title style="max-height: 900px;">
          <span class="subheader" v-if="action == 1 || action == 4"> Add Item</span>
          <span class="subheader" v-if="action == 2 || action == 3"> Edit Item</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="dialogAddItem = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
          <v-card-text class="pa-4">
            <v-form @submit.prevent ref="formRefItem">
                <v-row no-gutters>
                  <v-col cols="12" md="12" class="px-2">
                        <v-text-field
                        dense
                        outlined
                        color="header"
                        v-model="formData2.StockPropertyNo"
                        label="Stock/Property No."
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="px-2">
                        <v-text-field
                        dense
                        outlined
                        color="header"
                        v-model="formData2.ItemName"
                        :rules="[formRules.required]"
                        label="Item Name"
                        :disabled="formData.RefTypeID == 3 || formData.RefTypeID == 4 || (formData.RefTypeID == 1 && formData.IARID != null)"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="px-2">
                        <v-textarea
                        dense
                        outlined
                        color="header"
                        v-model="formData2.ItemDescription"
                        label="Item Description"
                        rows="3"
                      ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="12" class="px-2">
                        <v-autocomplete
                          v-model="formData2.UnitMeasureID"
                          label="Unit Measure"
                          :rules="[formRules.required]"
                          dense
                          outlined
                          color="header"
                          :items="unitmeasures"
                          item-text="description"
                          item-value="id"
                          type="text"
                          :disabled="formData.RefTypeID == 3 || formData.RefTypeID == 4 || (formData.RefTypeID == 1 && formData.IARID != null)"
                        ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" class="px-2">
                        <v-text-field
                        dense
                        outlined
                        color="header"
                        v-model="formData2.UnitCost"
                        :rules="[formRules.required]"
                        label="Unit Cost"
                        type="number"
                        :disabled="formData.RefTypeID == 3 || formData.RefTypeID == 4 || (formData.RefTypeID == 1 && formData.IARID != null)"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="px-2">
                        <v-text-field
                        dense
                        outlined
                        color="header"
                        v-model="formData2.Qty"
                        :rules="[formRules.required, checkBalance()]"
                        label="Qty"
                        type="number"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="px-2">
                        <v-text-field
                        dense
                        outlined
                        readonly
                        color="header"
                        :value="formData2.UnitCost * formData2.Qty"
                        label="Amount"
                        type="number"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="px-2" v-if="formData.RefTypeID != 3 && formData.RefTypeID != 4">
                        <v-menu
                        dense
                        ref="menu13"
                        v-model="menu13"
                        :close-on-content-click="false"
                        :return-value.sync="formData2.EUL"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            dense
                            v-model="formData2.EUL"
                            :rules="[formRules.required]"
                            label="Estimated Useful Life"
                            readonly
                            :disabled="formData.RefTypeID == 1 && formData.IARID != null"
                            outlined
                            color="header"
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData2.EUL"
                          no-title
                          scrollable
                          color="header"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="header" @click="menu13 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="header"
                            @click="$refs.menu13.save(formData2.EUL)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                  </v-col>
                </v-row>
                  </v-form>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="dialogAddItem = false"> Cancel </v-btn>
          <v-btn
            color="header"
            class="white--text"
            @click="saveItem()"
            :loading="isLoading"
            v-if="action == 1 || action == 4"
          >
          <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
            Save
          </v-btn>
          <v-btn
            color="primary"
            class="white--text"
            :loading="isLoading"
            @click="updateItem()"
            v-if="action == 2"
          >
          <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
            Update
          </v-btn>
          <v-btn
            color="primary"
            class="white--text"
            :loading="isLoading"
            @click="updateItem2()"
            v-if="action == 3"
          >
          <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmSubmit" scrollable max-width="500px">
      <v-card>
        <v-card-title class="headline pt-5">Confirmation</v-card-title>
        <v-card-text style="max-height: 900px">
        <div>
          Are you sure you want to submit this 
          <span v-if="riaData.type == 1 && formData.RefTypeID != 4">Inventory Custodian Slip</span>
          <span v-if="riaData.type == 2 && formData.RefTypeID != 4">Property Acknowledgement Receipt</span>
          <span v-if="formData.RefTypeID == 4">Requisition and Issuance Slip</span>
          ?
        </div>
        <br />
        <div>
          <i>
          <b>NOTE: </b>
          <span v-if="riaData.type == 1 && formData.RefTypeID != 4">ICS</span>
          <span v-if="riaData.type == 2 && formData.RefTypeID != 4">PAR</span>
          <span v-if="formData.RefTypeID == 4">RIS</span>&nbsp;

          <span v-if="formData.RefTypeID == 1 && formData.IARID == null">will be created WITHOUT reference from the RIS and IAR.</span>
          <span v-else-if="formData.RefTypeID == 1 && formData.IARID != null">will be created WITHOUT reference from the RIS (IAR only).</span>
          <span v-else-if="formData.RefTypeID == 2">will be created WITH reference from the RIS and IAR.</span>
          <span v-else-if="formData.RefTypeID == 3">will be created WITH reference from the RIS ONLY.</span>
          <span v-else-if="formData.RefTypeID == 4">will be created WITH reference from the IAR.</span>
          </i>
        </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" outlined @click="dialogConfirmSubmit = false">Cancel</v-btn>
          <!-- <v-btn color="green darken-2 white--text" v-if="risData.edit" @click="confirmUpdateRIS()" :loading="risLoading"><v-icon>mdi-pencil-box</v-icon> Update</v-btn> -->
          <v-btn color="green darken-2 white--text" v-if="formData.RefTypeID == 1" @click="confirmSubmitRIA()" :loading="riaLoading"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
          <v-btn color="green darken-2 white--text" v-if="formData.RefTypeID == 2" @click="confirmSubmitIAR()" :loading="riaLoading"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
          <v-btn color="green darken-2 white--text" v-if="formData.RefTypeID == 3" @click="confirmSubmitRIS()" :loading="riaLoading"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
          <v-btn color="green darken-2 white--text" v-if="formData.RefTypeID == 4" @click="confirmSubmitRISONLY()" :loading="riaLoading"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RISModal :risData="risData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
</div>
</template>
<script>
export default {
  components: {
    RISModal: () => import("./ViewRIS.vue"),
  },
  props: {
    riaData: {},
  },
  data: () => ({
    searchText: '',
    dialogRIA: false,
    formData: {
      IARID: null,
      RISID: 0,
      FundID: null,
      DispenseTypeID: 1,
      items: [],
    },
    formData2: {
      StockPropertyNo: null,
      ItemName: null,
      ItemDescription: null,
      UnitMeasureID: null,
      UnitMeasureDescription: null,
      UnitCost: null,
      Qty: null,
      Amount: null,
      EUL: null
    },
    formData3: {
      IARID: null,
      RISID: 0,
      FundID: null,
      items: [],
    },
    formData4: {
      IARCODE: null,
      DateCreated: null,
      UploadedSupplierID: null,
      InvoiceNo: null,
      DateInvoice: null,
      DateInspectedStart: null,
      DateInspectedEnd: null,
      RecieverUserID: null,
      Status: null,
      statusid: null,
      DateReceivedStart: null,
      DateReceivedEnd: null,
      DateDelivered: null,
      FundID: null,
      SpecificFundSourceID: null,
      items: [],
    },
    dialogAddItem: false,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        align: "center",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "Qty", align:"center", value: "Qty", sortable: false },
      { text: "EUL", align:"center", value: "EUL", sortable: false },
      { text: "Actions", align:"center", value: "Actions", sortable: false },
    ],
    itemList: [],
    selectedItems: [],
    loading: false,
    dialogConfirmSubmit: false,
    showItems: false,
    funds: [],
    specificfundsources: [],
    users: [],
    dispensetypes: [],
    unitmeasures: [],
    risData: [],
    dialogRefType: false,
    ref_types: [
      {id: 1, description: 'ICS/PAR ONLY', icon: 'mdi-file'},
      {id: 2, description: 'ICS/PAR, RIS, AND IAR', icon: ' mdi-file-multiple'},
      {id: 3, description: 'ICS/PAR AND RIS', icon: 'mdi-file-replace'},
      {id: 4, description: 'RIS ONLY', icon: 'mdi-file-outline'},
    ],
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    menu7: false,
    menu8: false,
    menu9: false,
    menu10: false,
    menu11: false,
    menu12: false,
    menu13: false,
    menu14: false,
    menu15: false,
    menu16: false,
    iars: [],
    isLoading: false,
    expanded2: [],
    expanded3: [],
    hint_brand: '',
    hint_model: '',
    hint_serial_no: '',
    riaLoading: false,
    action: 0,
    rislist: [{RISID: 0, RISCODE: "Not Yet Uploaded"}],
    switchQty: true,
    items_temp: [],
    suppliers: [],
    formData2_temp: [],
  }),
  watch: {
    searchText: {
      handler() {
      },
      deep: true,
    },
    riaData: {
      handler(data) {
        if (data.type) {
          this.formData = {
            IARID: null,
            RISID: 0,
            CODE: null,
            DateCreated: null,
            Purpose: null,
            FundID: null,
            SpecificFundSourceID: null,
            DateRequested: null,
            ApprovedDate: null,
            IssuedDate: null,
            DispenseTypeID: 1,
            items: [],
          };
          this.formData2 = {
            StockPropertyNo: null,
            ItemName: null,
            ItemDescription: null,
            UnitMeasureID: null,
            UnitMeasureDescription: null,
            UnitCost: null,
            Qty: null,
            Amount: null,
            EUL: null
          };
          this.formData3 = {
            IARID: null,
            RISCODE: null,
            DateCreated: null,
            RISPurpose: null,
            UserID: null,
            RequestedDate: null,
            ApprovedDate: null,
            IssuedDate: null,
            items: [],
          };
          if(data.type == 1){
            this.ref_types = [
              {id: 1, description: 'ICS ONLY', icon: 'mdi-file'},
              {id: 2, description: 'ICS, RIS, AND IAR', icon: ' mdi-file-multiple'},
              {id: 3, description: 'ICS AND RIS', icon: 'mdi-file-replace'},
              {id: 4, description: 'RIS ONLY', icon: 'mdi-file-outline'},
            ];
          }
          else if(data.type == 2){
            this.ref_types = [
              {id: 1, description: 'PAR ONLY', icon: 'mdi-file'},
              {id: 2, description: 'PAR, RIS, AND IAR', icon: ' mdi-file-multiple'},
              {id: 4, description: 'RIS ONLY', icon: 'mdi-file-outline'},
            ];
          }
          if(data.edit){
              this.formData.FundID = data.FundID
              this.formData.Description = data.Description
              this.formData.Date = data.Date.split(' ')[0];
              this.selectedItems = data.items;
              this.getRISList(parseInt(this.formData.FundID))
          }
        }
        this.dialogRefType = true;
      },
      deep: true,
    },
    switchQty: {
      handler(data) {
        this.showZeroQty(data);
      },
      deep: true,
    },
  },
  mounted() {
    this.getUtilities();
    this.getIARCodes();
    this.getPOUtil();
    this.eventHub.$on("closeViewRIS", () => {
      this.getRISList(this.formData.FundID);
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewRIS");
  },
  methods: {
    closeDialog(){
      this.dialogRIA = false;
      this.dialogConfirmSubmit = false;
      this.showItems = false;
      // this.$refs.RIAFormRef.reset()
      this.eventHub.$emit("closeCreateRIA", false);
    },
    addIARItem(){
      this.action = 4;
      this.dialogAddItem = true;
    },
    addICSItem(){
      this.action = 1;
      this.dialogAddItem = true;
    },
    saveItem(){
      if (this.$refs.formRefItem.validate()){
        let MRData = [];
        let ln = this.formData.items.length
        for (let index = 0; index < this.formData2.Qty; index++) {
          MRData.push({
            Brand: null,
            Model: null,
            SerialNo: null
          });
        }
        this.unitmeasures.forEach(item => {
          if(item.id == this.formData2.UnitMeasureID){
            this.formData2.UnitMeasureDescription = item.description;
          }
        });
        let item = {
          num: ln > 0 ? this.formData.items[ln-1].num + 1 : 1,
          StockPropertyNo: this.formData2.StockPropertyNo,
          ItemName: this.formData2.ItemName,
          ItemDescription: this.formData2.ItemDescription,
          UnitMeasureID: this.formData2.UnitMeasureID,
          UnitMeasureDescription: this.formData2.UnitMeasureDescription,
          UnitCost: this.formData2.UnitCost,
          Qty: this.formData2.Qty,
          Amount: this.formData2.Amount,
          EUL: this.formData2.EUL,
          MRData: MRData,
          MRData_str: {
            Brand: null,
            Model: null,
            SerialNo: null 
          }
        }
        this.formData.items.push(item);
        if(this.action == 4){
          this.formData2.num = this.formData2.StockPropertyNo + '_' + this.formData2.ItemName + '_' + this.formData2.ItemDescription + '_' + ln > 0 ? this.formData.items[ln-1].num + 1 : 1,
          this.formData3.items.push(JSON.parse(JSON.stringify(this.formData2)));
          this.formData4.items.push(JSON.parse(JSON.stringify(this.formData2)));
        }
        this.dialogAddItem = false;
        this.$refs.formRefItem.reset();
      }
    },
    saveRefType(){
      this.dialogRefType = false
      this.dialogRIA = true;
    },
    editItem(item){
      this.dialogAddItem = true;
      this.formData2 = item;
      this.formData2_temp = JSON.parse(JSON.stringify(item));
      this.action = 2;
    },
    updateItem(){
      if (this.$refs.formRefItem.validate()){
      let ft = this.formData2_temp
      let MRData = ft.MRData;
      let qty_new = this.formData2.Qty - ft.Qty
      if(qty_new > 0){
        for (let index = 0; index < qty_new; index++) {
          MRData.push({
            Brand: null,
            Model: null,
            SerialNo: null
          });
        }
      }
      else if(qty_new < 0){
        for (let index = 0; index < parseInt(ft.Qty - this.formData2.Qty); index++) {
          MRData.pop();
        }
      }
      this.formData2.MRData = MRData;
      this.dialogAddItem = false;
      }
    },
    editItem2(item){
      this.dialogAddItem = true;
      this.formData2 = item;
      this.action = 3;
    },
    updateItem2(){
      if (this.$refs.formRefItem.validate()){
        this.dialogAddItem = false;
      }
    },
    removeItem(index){
      this.formData.items.splice(index, 1);
    },
    removeItem2(index){
      this.formData3.items.splice(index, 1);
    },
    removeItem3(index){
      this.formData4.items.splice(index, 1);
    },
    copyItem(){
      this.formData3.items = this.formData.items
    },
    submitRIA(){
      if(this.$refs.RIAFormRef.validate() && this.formData.items.length > 0){
        this.dialogConfirmSubmit = true;
      }
    },
    confirmSubmitRIA(){
      if(this.$refs.RIAFormRef.validate() && this.formData.items.length > 0){
      this.riaLoading = true;
      if(this.riaData.type == 1){
      let data = new FormData();
        data.append("ICSCODE", this.formData.CODE);
        data.append("IARID", this.formData.IARID || 0);
        data.append("RISID", this.formData.RISID || 0);
        data.append("DateCreated", this.formData.DateCreated);
        data.append("FundID", this.formData.FundID);
        data.append("SpecificFundSourceID", this.formData.SpecificFundSourceID);
        data.append("ICSPurpose", this.formData.Purpose);
        data.append("UserID", this.formData.UserID);
        data.append("DispenseTypeID", this.formData.DispenseTypeID);
        data.append("DateRequested", this.formData.DateRequested);
        data.append("ApprovedDate", this.formData.ApprovedDate);
        data.append("IssuedDate", this.formData.IssuedDate);
        data.append("List", JSON.stringify(this.formData.items));
        this.axiosCall("/ics/workback/add", "POST", data).then((res) => {
        if(res.data.status){
          window.open(this.apiUrl + "/ics/report/" + res.data.data[0].ICSID);
          this.closeDialog();
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
        this.riaLoading = false;
        });
      }
      if(this.riaData.type == 2){
      let data = new FormData();
        data.append("PARCODE", this.formData.CODE);
        data.append("IARID", this.formData.IARID || 0);
        data.append("RISID", this.formData.RISID || 0);
        data.append("DateCreated", this.formData.DateCreated);
        data.append("FundID", this.formData.FundID);
        data.append("SpecificFundSourceID", this.formData.SpecificFundSourceID);
        data.append("PARPurpose", this.formData.Purpose);
        data.append("UserID", this.formData.UserID);
        data.append("DispenseTypeID", this.formData.DispenseTypeID);
        data.append("DateRequested", this.formData.DateRequested);
        data.append("ApprovedDate", this.formData.ApprovedDate);
        data.append("IssuedDate", this.formData.IssuedDate);
        data.append("List", JSON.stringify(this.formData.items));
        this.axiosCall("/par/workback/add", "POST", data).then((res) => {
        if(res.data.status){
          window.open(this.apiUrl + "/par/report/" + res.data.data[0].PARID);
          this.closeDialog();
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
        this.riaLoading = false;
        });
      }
      }
    },
    confirmSubmitRIS(){
      if(this.$refs.RIAFormRef.validate() && this.formData.items.length > 0){
        if(this.formData.RISID > 0){
          this.confirmSubmitRIA();
        }
        else{
        this.riaLoading = true;
        let data = new FormData();
          data.append("RISCODE", this.formData3.RISCODE);
          data.append("IARID", this.formData.IARID);
          data.append("DateCreated", this.formData3.DateCreated);
          data.append("FundID", this.formData.FundID);
          data.append("SpecificFundSourceID", this.formData.SpecificFundSourceID);
          data.append("RISPurpose", this.formData3.RISPurpose);
          data.append("UserID", this.formData3.UserID);
          data.append("RequestedDate", this.formData3.RequestedDate);
          data.append("ApprovedDate", this.formData3.ApprovedDate);
          data.append("IssuedDate", this.formData3.IssuedDate);
          data.append("List", JSON.stringify(this.formData3.items));
          this.axiosCall("/ics/workback/ris/add", "POST", data).then((res) => {
          if(res.data.status){
            // window.open(this.apiUrl + "/ris/report/" + res.data.data[0].RISID + '/1');
            this.formData.RISID = res.data.data[0].RISID;
            this.confirmSubmitRIA();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
          }
          this.riaLoading = false;
          });
        }
      }
    },
    confirmSubmitRISONLY(){
      if(this.$refs.RIAFormRef.validate() && this.formData3.items.length > 0){
        this.riaLoading = true;
        let data = new FormData();
          data.append("RISCODE", this.formData3.RISCODE);
          data.append("IARID", this.formData.IARID);
          data.append("DateCreated", this.formData3.DateCreated);
          data.append("FundID", this.formData.FundID);
          data.append("SpecificFundSourceID", this.formData.SpecificFundSourceID);
          data.append("RISPurpose", this.formData3.RISPurpose);
          data.append("UserID", this.formData3.UserID);
          data.append("RequestedDate", this.formData3.RequestedDate);
          data.append("ApprovedDate", this.formData3.ApprovedDate);
          data.append("IssuedDate", this.formData3.IssuedDate);
          data.append("List", JSON.stringify(this.formData3.items));
          this.axiosCall("/ics/workback/ris/add", "POST", data).then((res) => {
          if(res.data.status){
            window.open(this.apiUrl + "/ris/report/" + res.data.data[0].RISID + '/1');
            this.closeDialog();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
          }
          this.riaLoading = false;
          });
        }
    },
    confirmSubmitIAR(){
      if(this.$refs.RIAFormRef.validate() && this.formData.items.length > 0){
        this.riaLoading = true;
        let data = new FormData();
          data.append("IARCODE", this.formData4.IARCODE);
          data.append("DateCreated", this.formData4.DateCreated);
          data.append("UploadedSupplierID", this.formData4.UploadedSupplierID);
          data.append("InvoiceNo", this.formData4.InvoiceNo);
          data.append("DateInvoice", JSON.stringify(this.formData4.DateInvoice));
          data.append("DateDelivered", JSON.stringify(this.formData4.DateDelivered));
          data.append("DateInspectedStart", this.formData4.DateInspectedStart);
          data.append("DateInspectedEnd", this.formData4.DateInspectedEnd);
          data.append("Status", this.formData4.Status);
          data.append("DateReceivedStart", this.formData4.DateReceivedStart);
          data.append("DateReceivedEnd", this.formData4.DateReceivedEnd);
          data.append("RecieverUserID", this.formData4.RecieverUserID);
          data.append("FundID", this.formData4.FundID);
          data.append("SpecificFundSourceID", this.formData4.SpecificFundSourceID);
          data.append("List", JSON.stringify(this.formData4.items));
          this.axiosCall("/ics/workback/iar/add", "POST", data).then((res) => {
          if(res.data.status){
            // window.open(this.apiUrl + "/ris/report/" + res.data.data[0].RISID + '/1');
            this.formData.IARID = res.data.data.iar_details[0].IARID;
            this.formData.FundID = this.formData4.FundID;
            this.formData.SpecificFundSourceID = this.formData4.SpecificFundSourceID;
            res.data.data.iar_items.forEach(item => {
              this.formData4.items.forEach(item4 => {
                if(item.StockPropertyNo == item4.StockPropertyNo && item.ItemName == item4.ItemName && parseFloat(item.UnitCost) == parseFloat(item4.UnitCost)){
                  item4.IARItemID = item.IARItemID;
                }
              });
              this.formData3.items.forEach(item3 => {
                if(item.StockPropertyNo == item3.StockPropertyNo && item.ItemName == item3.ItemName && parseFloat(item.UnitCost) == parseFloat(item3.UnitCost)){
                  item3.IARItemID = item.IARItemID;
                }
              });
              this.formData.items.forEach(item1 => {
                if(item.StockPropertyNo == item1.StockPropertyNo && item.ItemName == item1.ItemName && parseFloat(item.UnitCost) == parseFloat(item1.UnitCost)){
                  item1.IARItemID = item.IARItemID;
                }
              });
            });
            this.confirmSubmitRIS();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
          }
          this.riaLoading = false;
          });
        }
    },
    getUtilities() {
      this.axiosCall("/csv/utilities", "GET").then((res) => {
        this.funds = res.data.data.funds;
        this.specificfundsources = res.data.data.sfs;
        this.users = res.data.data.users;
        this.dispensetypes = res.data.data.dispensetypes;
        this.unitmeasures = res.data.data.unitmeasures;
      });
    },
    getIARCodes(){
      this.axiosCall("/iar/getIARCodes", "GET").then((res) => {
        this.iars = res.data.data;
      });
    },
    getIARItems(IARID){
      this.formData.items = [];
      this.formData3.items = [];
      this.formData.FundID = null;
      this.formData.SpecificFundSourceID = null;
      this.rislist = [];
      if(IARID != null){
      let data = new FormData();
      data.append("IARID", IARID);
      data.append("type", this.riaData.type);
      this.axiosCall("/iar/getIARItems", "POST", data).then((res) => {
        this.rislist = [{RISID: 0, RISCODE: "Not Yet Uploaded"}, ...res.data.data.rislist];
        this.formData.items = res.data.data.items;
        this.formData3.items = JSON.parse(JSON.stringify(res.data.data.items));
        this.formData.FundID = res.data.data.iardata.FundID;
        this.formData.SpecificFundSourceID = res.data.data.iardata.SpecificFundSourceID;
        this.items_temp = JSON.parse(JSON.stringify(res.data.data.items));
        this.formData.items.forEach(item => {
          let MRData = [];
          for (let index = 0; index < parseInt(item.Qty); index++) {
            MRData.push({
              Brand: null,
              Model: null,
              SerialNo: null
            });
          }
          item.MRData = MRData;
          item.MRData_str = {
            Brand: null,
            Model: null,
            SerialNo: null 
          }
        });
        this.items_temp.forEach(item => {
          let MRData = [];
          for (let index = 0; index < parseInt(item.Qty); index++) {
            MRData.push({
              Brand: null,
              Model: null,
              SerialNo: null
            });
          }
          item.MRData = MRData;
          item.MRData_str = {
            Brand: null,
            Model: null,
            SerialNo: null 
          }
        });
        // this.formData3.items = [];
      });
      }
    },
    getRISItems(RISID){
      let data = new FormData();
      data.append("RISID", RISID);
      data.append("IARID", this.formData.IARID);
      data.append("type", this.riaData.type);
      this.axiosCall("/ris/getRISItems", "POST", data).then((res) => { 
        this.formData.items = res.data.data.items;
        this.formData3.items = JSON.parse(JSON.stringify(res.data.data.items));
        this.formData.FundID = res.data.data.iardata.FundID;
        this.formData.SpecificFundSourceID = res.data.data.iardata.SpecificFundSourceID;
        this.items_temp = JSON.parse(JSON.stringify(res.data.data.items));
        this.formData.items.forEach(item => {
          let MRData = [];
          for (let index = 0; index < parseInt(item.Qty); index++) {
            MRData.push({
              Brand: null,
              Model: null,
              SerialNo: null
            });
          }
          item.MRData = MRData;
          item.MRData_str = {
            Brand: null,
            Model: null,
            SerialNo: null 
          }
        });
        this.items_temp.forEach(item => {
          let MRData = [];
          for (let index = 0; index < parseInt(item.Qty); index++) {
            MRData.push({
              Brand: null,
              Model: null,
              SerialNo: null
            });
          }
          item.MRData = MRData;
          item.MRData_str = {
            Brand: null,
            Model: null,
            SerialNo: null 
          }
        });
        // this.formData3.items = [];
      });
    },
    fillBrands(data){
      let brands = data.MRData_str.Brand.split(",");
      data.MRData.forEach((item, index) => {
        item.Brand = brands[index];
      });
      if(brands.length > 0){
        this.hint_brand = "No. of Entries: " + brands.length;
      }
    },
    fillModels(data){
      let models = data.MRData_str.Model.split(",");
      data.MRData.forEach((item, index) => {
        item.Model = models[index];
      });
      if(models.length > 0){
        this.hint_model = "No. of Entries: " + models.length;
      }
    },
    fillSerialNos(data){
      let serial_nos = data.MRData_str.SerialNo.split(",");
      data.MRData.forEach((item, index) => {
        item.SerialNo = serial_nos[index];
      });
      if(serial_nos.length > 0){
        this.hint_serial_no = "No. of Entries: " + serial_nos.length;
      }
    },
    showZeroQty(data){
      if(data == false){
        let items = [];
        this.items_temp.forEach(item => {
          if(item.Qty > 0){
            items.push(item)
          }
        });
        this.formData.items = items;
      }
      if(data == true){
        this.formData.items = this.items_temp;
      }
    },
    checkBalance(){
      if(this.formData.RefTypeID == 3 || this.formData.RefTypeID == 4 || (this.formData.RefTypeID == 1 && this.formData.IARID != null)){
      let oaq = this.formData2.OverAllQty;
      let rq = this.formData2.MRQty;
      let qty = this.formData2.Qty;
      if((oaq - rq) >= qty){
        return true;
      }
      else{
        return "Insufficient Quantity";
      }
    }
    else{
      return true;
    }
    },
    isDeletableItem(data){
      let notExists = true;
      this.formData.items.forEach(item => {
        if(item.ItemID == data.ItemID && item.num == data.num){
          notExists = false
        }
      });
      return notExists;
    },
    isDeletableItem2(data){
      let notExists = true;
      this.formData3.items.forEach(item => {
        if(item.num == data.num){
          notExists = false
        }
      });
      return notExists;
    },
    getPOUtil() {
      let data = new FormData();
      this.axiosCall("/get/all/POUtil", "POST", data).then((res) => {
        this.suppliers = res.data.data.supplier;
      });
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
.chip-width {
  width: 184px !important;
}
</style>